(function(window, document){

    const config = {    
        apiKey: "AIzaSyArkSoT39Hj5KMDT9bPboUog5yYO63zX6E",
        authDomain: "cat-production.firebaseapp.com",
        databaseURL: "https://cat-production.firebaseio.com",
        projectId: "cat-production",
        storageBucket: "cat-production.appspot.com",
        messagingSenderId: "883915272209",
        appId: "1:883915272209:web:f8116277d9de3f71e901b0"
    }
    
    firebase.initializeApp(config)

    window.addEventListener("hashchange", (e) => {
        e.preventDefault()
        return false        
    })

    document.querySelectorAll('input, textarea, select').forEach(el => {
        el.addEventListener("input", e => {
            showValidation(el)
        })
        el.addEventListener("blur", e => {
            showValidation(el)
        })
    })
    
    document.querySelectorAll('form').forEach(el => {
        el.addEventListener("submit", e => {
            e.preventDefault()
        })
    })
   
    document.querySelectorAll('button[type="submit"]').forEach(el => {
        el.addEventListener("click", e => {
            const form = el.closest('form')
            submitForm(form, el)
        })
    })

    $('div.blogcms-container').each(function(){
        var blogContainerView = new window.BlogContainerView({
            el: $(this)
        });
    });

    // const openPopupEl = document.getElementsByClassName('open-popup')[0]
    // openPopupEl.addEventListener('click', (e) => {
    //     handlePopup(e)
    // })
    // const checkbox = document.querySelector('input[type="checkbox"][name="privacy"]')
    // checkbox.addEventListener('click', (e) => {
    //     handlePopup(e)
    // })

    function handlePopup(e){
        e.preventDefault()

        let ready = false

        const popup = document.getElementById('popup')
        const center = popup.querySelector('div#center')
        const content = center.querySelector('div#content')
        const footer = center.querySelector('div#footer')
        const button = footer.querySelector('button')
        const notice = footer.querySelector('div#notice')
        const checkbox = document.querySelector('input[type="checkbox"][name="privacy"]')

        button.classList.add('disabled')
        popup.style.display = 'block'
        notice.style.display = 'block'
        content.scrollTop = 0

        if(content.scrollHeight - content.offsetHeight === 0){
            setReady(true)
        }

        function listenToScroll(e){
            const target = e.target
            if(target.scrollHeight - target.offsetHeight === target.scrollTop){
                setReady(true)
            }
        }
        function closePopup(){
            if(ready){
                button.removeEventListener('click', closePopup)
                content.removeEventListener('scroll', listenToScroll)
                popup.style.display = 'none'
                checkbox.checked = true
            }else{
                window.alert('scrolle ganz nach unten...')
            }
        }
        function setReady(value){
            ready = value
            if(value){
                button.classList.remove('disabled')
                notice.style.display = 'none'
            }else{
                button.classList.add('disabled')
                notice.style.display = 'block'
            }
        }

        content.addEventListener('scroll', (e) => {
            listenToScroll(e)
        })
        button.addEventListener('click', (e) => {
            closePopup()
        })
    }


    function validateForm(form){
        const fields = form.querySelectorAll('input, textarea, select')
        const validity = Array.from(fields).map(el => {
            return el.type === 'select-one' ? (el.validity.valid && el[el.selectedIndex].value !== 'false') : el.validity.valid
        })
        fields.forEach(el => {
            showValidation(el)
        })
        return _.every(validity)
    }

    function serializeForm(form){
        const fields = form.querySelectorAll('input, textarea, select')
        const data = Array.from(fields).reduce((output, el) => {
            switch(el.type){
                case 'checkbox': 
                    output[el.name] = el.checked
                    break
                case 'select-one':
                    if(el && el[el.selectedIndex]){
                        output[el.name] = el[el.selectedIndex].value
                    }
                    break
                default: 
                    output[el.name] = el.value
            }
            return output
        }, {})
        return data
    }
    
    function showValidation(el){
        const valid = el.type === 'select-one' ? el.validity.valid && el[el.selectedIndex].value !== 'false' : el.validity.valid
        if(valid){
            el.classList.remove('invalid')
            el.classList.add("valid")
        }else{
            el.classList.remove('valid')
            el.classList.add("invalid")
        }
    }
    function submitForm(form, el){
        const resultMessage = form.querySelector('div#result-message')
        el.disabled = true
        const valid = validateForm(form)
        if(valid){
            const data = serializeForm(form)
            sendMailOverFirebase(data).then(() => {
                el.disabled = false
                // el.firstChild.data = 'Erfolgreich gesendet'
                resultMessage.style = 'display: block;'
                resetForm(form)
                //el.firstChild.data = 'Absenden'
            }).catch(error => {
                window.alert('Senden fehlgeschlagen! Bitte versuchen Sie es später nochmal.')
            })
        }else{
            el.disabled = false
        }
    }
    function resetForm(form){
        const fields = form.querySelectorAll('input, textarea, select')
        const data = Array.from(fields).map(el => {
            switch(el.type){
                case 'checkbox': 
                    el.checked = false
                    break
                case 'select-one': 
                    el.selectedIndex = 0
                    break
                default: 
                    el.value = ''
                    break
            }
        })
    }

    function sendMailOverFirebase(data){
        const sendMail = firebase.app().functions('europe-west1').httpsCallable('sendMail')
        return sendMail(data)
    }
    
    window.openMenu = function openMenu(e){
        document.getElementsByTagName('header')[0].getElementsByTagName('ul')[0].setAttribute('style', 'display: block;')
    }
    window.hideMenu = function hideMenu(e){
        document.getElementsByTagName('header')[0].getElementsByTagName('ul')[0].setAttribute('style', 'display: none;')
    }
    
}(window, document))

